<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <template>
      <br />
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Nuevo movimiento' }]" />
      <v-toolbar color="primary" dark card>
        <v-toolbar-title>Nuevo movimiento de stock</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Datos Generales</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Ingresar stock</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3">Resumen</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-5">
              <v-text-field
                v-model="name"
                :disabled="processingForm"
                label="Bodega"
                outline
                readonly
              />
              <v-autocomplete
                v-model="form.tipo_movimiento_id"
                :items="mov_tipos"
                dense
                outline
                clearable
                small-chips
                label="Seleccionar Tipo de Movimiento"
                item-text="nombre"
                item-value="id"
                :disabled="processingForm"
                :error="!!formErrors.tipo_movimiento_id"
                :error-messages="formErrors.tipo_movimiento_id"
                @change="
                  () => {
                    formErrors.tipo_movimiento_id = undefined;
                    delete formErrors.tipo_movimiento_id;
                  }
                "
              />
              <v-text-field
                v-model="form.guia_despacho_id"
                :disabled="processingForm"
                label="N° de Guia Despacho o Identificador único *"
                outline
                :rules="rules.guia_despacho_id"
                :error="!!formErrors.guia_despacho_id"
                :error-messages="formErrors.guia_despacho_id"
                @keyup="
                  () => {
                    formErrors.guia_despacho_id = undefined;
                    delete formErrors.guia_despacho_id;
                  }
                "
              />
              <v-text-field
                v-model="form.glosa"
                :disabled="processingForm"
                label="Glosa: Descripción del movimiento *"
                outline
                :rules="rules.glosa"
                :error="!!formErrors.glosa"
                :error-messages="formErrors.glosa"
                @keyup="
                  () => {
                    formErrors.glosa = undefined;
                    delete formErrors.glosa;
                  }
                "
              />
              <small>(*) No acepta valores repetidos.</small>
            </v-card>
            <v-btn
              color="primary"
              @click="e1 = 2"
              :disabled="
                form.tipo_movimiento_id == '' || form.glosa == '' || form.guia_despacho_id == ''
              "
            >
              Continuar
            </v-btn>
            <v-btn color="error" :to="{ name: 'Movimientos', params: { id: $route.params.id } }"
              >Volver</v-btn
            >
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-5">
              <v-layout row wrap>
                <v-flex sm6 style="margin-bottom:15px;">
                  <v-text-field
                    v-model="search"
                    box
                    append-icon="search"
                    label="Buscar producto"
                    clearable
                    hide-details
                  />
                </v-flex>
              </v-layout>
              <v-data-table
                :headers="[
                  { text: 'SKU-ITEM', value: 'SKU' },
                  { text: 'Producto', value: 'producto.nombre' },
                  { text: 'Item', value: 'nombre' },
                  { text: 'Categoría', value: 'categoria.nombre' },
                  { text: 'Stock', value: 'cantidad_stock' },
                  { text: 'Cantidad' }
                ]"
                :items="stockProductos"
                :search="search"
                :rows-per-page-items="[10, 15, 25, 50, 100, 150]"
                class="elevation-1"
              >
                <tr slot="items" slot-scope="props">
                  <td class="px-3">
                    {{ props.item.SKU }}
                  </td>
                  <td class="px-3">
                    {{ props.item.producto.nombre }}
                  </td>
                  <td class="px-3">
                    {{ props.item.nombre }}
                  </td>
                  <td class="px-3">
                    {{ props.item.categoria.nombre }}
                  </td>
                  <td class="px-3">
                    {{ props.item.cantidad_stock }}
                  </td>
                  <td class="px-3">
                    <v-text-field
                      v-model="props.item.cantidad"
                      type="number"
                      solo
                      style="height:60px !important; padding:5px;"
                    />
                  </td>
                </tr>
              </v-data-table>
            </v-card>

            <v-btn color="primary" @click="siguiente()">
              Continuar
            </v-btn>
            <v-btn @click="e1 = 1" color="error">Volver</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card class="mb-5">
            <v-layout row wrap>
              <v-flex md3 sm12>
                <v-card class="mb-5 md8" style="border:2px solid #1976d2 !important; padding:8px 12px 12px; border-radius:12px">
                  <div class="wrapper">
                    <div class="first"><h3>Bodega:</h3>{{ name }}</div>
                  </div>
                  <div class="wrapper">
                    <div class="first"><h3>N° de Guía:</h3>{{ form.guia_despacho_id }}</div>
                  </div>
                  <div class="wrapper">
                    <div class="first"><h3>Glosa:</h3>{{ form.glosa }}</div>
                  </div>
                </v-card>
                </v-flex>
                <v-flex md9 sm12>
                  <v-data-table
                    :headers="[
                      { text: 'Producto' },
                      { text: 'Nombre' },
                      { text: 'Cantidad' },
                      { text: 'Acciones' }
                    ]"
                    :items="this.form.item_productos"
                    :rows-per-page-items="[50, 100, 150]"
                    class="elevation-1"
                  >
                    <tr slot="items" slot-scope="props">
                      <td class="px-3">
                        {{ props.item.producto }}
                      </td>
                      <td class="px-3">
                        {{ props.item.nombre }}
                      </td>
                      <td class="px-3">
                        {{ props.item.cantidad }}
                      </td>
                      <td class="px-3">
                        <v-btn
                          class="ma-0"
                          small
                          icon
                          flat
                          color="error"
                          @click="removeTodo(props.item.item_producto_id)"
                        >
                          <v-icon small>
                            delete
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </v-data-table>
                </v-flex>
            </v-layout>

              <v-btn color="primary" @click="submitCreateMovimiento" v-if="this.form.item_productos.length > 0" :loading="loadingGuardar">
                Guardar
              </v-btn>

              <v-btn @click="e1 = 2" color="error">Volver</v-btn>
            </v-card>
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2" secundary-title>
                  {{ errorMessage }}
                </v-card-title>
                <v-card-text>
                  <v-flex xs12>
                    <v-data-table
                      :headers="[{ text: 'error' }]"
                      :items="errorItems"
                      hide-actions
                      class="elevation-1"
                    >
                      <tr slot="items" slot-scope="props">
                        <td class="px-3">
                          {{ props.item }}
                        </td>
                      </tr>
                    </v-data-table>
                  </v-flex>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat @click="dialog = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { baseUrlAPI } from "@/config/api";
import axios from "axios";
const HOST = baseUrlAPI;

export default {
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission")
  },
  data() {
    return {
      e1: 0,
      loadingGuardar: false,
      formErrors: {},
      errorItems: [],
      dialog: false,
      errorMessage: "",
      name: "",
      search: "",
      item1: "",
      items: [],
      producto: "",
      productos: [],
      cantidad: "",
      nombre: "",
      form: {
        glosa: "",
        bodega_id: "",
        fecha: "",
        guia_despacho_id: "",
        tipo_movimiento_id: "",
        estado: 1,
        item_productos: []
      },
      tipos: ["bodega", "tienda"],
      estados: [
        { id: 0, nombre: "inactivo" },
        { id: 1, nombre: "activo" }
      ],
      mov_tipos: [
        { id: 1, nombre: "Entrada" },
        { id: 2, nombre: "Salida" },
        { id: 3, nombre: "Ajuste de Inventario" }
      ],
      validForm: true,
      processingForm: false,
      rules: {}
    };
  },
  computed: {
    ...mapState({
      stockProductos: state => state.bodegas.stockProductos
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  mounted() {
    this.getProducts();
    this.getBodega({ bodegaId: this.$route.params.id }).then(response => {
      const bodegaInfo = response.data.data;
      this.setForm(bodegaInfo);
    });
    this.getProductosStock();
  },
  methods: {
    ...mapActions({
      createMovimiento: "bodegas/createMovimiento",
      getBodega: "bodegas/getBodega",
      getItems: "productos/getItems",
      getItem: "items/getItem",
      getProducto: "productos/getProducto",
      getProductosStock: "bodegas/getProductosStock"
    }),
    async getProducts() {
      try {
        const res = await axios.get(`${HOST}/productos/general`);
        this.productos = res.data.data;
      } catch (error) {
        this.$message.error("error al traer productos");
      }
    },
    setForm(bodega) {
      this.name = bodega.nombre;
      this.form.bodega_id = bodega.id;
    },
    async addItems() {
      const res = await this.getProducto({ productoId: this.producto });
      const producto = res.data.data.nombre;
      this.getItem({ itemId: this.item1 }).then(response => {
        this.nombre = response.data.data.nombre;
        if (this.producto != null) {
          this.form.item_productos.push({
            producto: producto,
            item_producto_id: this.item1,
            cantidad: this.cantidad,
            nombre: this.nombre
          });
        }
      });
    },
    filterItems() {
      if (this.producto === undefined) {
        this.items = [];
        this.cantidad = "";
        return;
      }
      this.getItems({ productoId: this.producto }).then(response => {
        this.items = response.data.data;
      });
    },

    siguiente() {
      let datos = this.stockProductos.filter(item => item.cantidad);
      this.form.item_productos = datos.map(function(obj) {
        let dato = {
          item_producto_id: obj.id,
          cantidad: obj.cantidad,
          producto: obj.producto.nombre,
          nombre: obj.nombre
        };
        return dato;
      });
      this.e1 = 3;
    },
    removeTodo(id) {
      let items = this.form.item_productos;
      items = items.filter(item => item.item_producto_id != id);
      this.form.item_productos = items;
    },
    submitCreateMovimiento() {
      this.loadingGuardar = true;
      this.createMovimiento({ data: this.form })
        .then(response => {
          this.$router.push({ name: "Movimientos", params: { id: this.$route.params.id } });
        })
        .catch(error => {
          this.errorMessage = error.response.data.message;
          this.formErrors = error.response.data.errors || {};
          this.errorItems = Object.values(this.formErrors);
          this.dialog = true;
        });      
    }
  }
};
</script>
<style scoped>
.wrapper {
  width: 700px;
  overflow: hidden;
}
.first {
  width: 200px;
  float: left;
}
.second {
  float: left;
  margin-left: 30px;
}
</style>
