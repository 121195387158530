var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[[_c('br'),_c('Breadcrumbs',{attrs:{"routes":[{ name: 'Inicio' }, { name: 'Nuevo movimiento' }]}}),_c('v-toolbar',{attrs:{"color":"primary","dark":"","card":""}},[_c('v-toolbar-title',[_vm._v("Nuevo movimiento de stock")]),_c('v-spacer')],1),_c('v-stepper',{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v("Datos Generales")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"step":"2"}},[_vm._v("Ingresar stock")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 3,"step":"3"}},[_vm._v("Resumen")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Bodega","outline":"","readonly":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-autocomplete',{attrs:{"items":_vm.mov_tipos,"dense":"","outline":"","clearable":"","small-chips":"","label":"Seleccionar Tipo de Movimiento","item-text":"nombre","item-value":"id","disabled":_vm.processingForm,"error":!!_vm.formErrors.tipo_movimiento_id,"error-messages":_vm.formErrors.tipo_movimiento_id},on:{"change":function () {
                  _vm.formErrors.tipo_movimiento_id = undefined;
                  delete _vm.formErrors.tipo_movimiento_id;
                }},model:{value:(_vm.form.tipo_movimiento_id),callback:function ($$v) {_vm.$set(_vm.form, "tipo_movimiento_id", $$v)},expression:"form.tipo_movimiento_id"}}),_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"N° de Guia Despacho o Identificador único *","outline":"","rules":_vm.rules.guia_despacho_id,"error":!!_vm.formErrors.guia_despacho_id,"error-messages":_vm.formErrors.guia_despacho_id},on:{"keyup":function () {
                  _vm.formErrors.guia_despacho_id = undefined;
                  delete _vm.formErrors.guia_despacho_id;
                }},model:{value:(_vm.form.guia_despacho_id),callback:function ($$v) {_vm.$set(_vm.form, "guia_despacho_id", $$v)},expression:"form.guia_despacho_id"}}),_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Glosa: Descripción del movimiento *","outline":"","rules":_vm.rules.glosa,"error":!!_vm.formErrors.glosa,"error-messages":_vm.formErrors.glosa},on:{"keyup":function () {
                  _vm.formErrors.glosa = undefined;
                  delete _vm.formErrors.glosa;
                }},model:{value:(_vm.form.glosa),callback:function ($$v) {_vm.$set(_vm.form, "glosa", $$v)},expression:"form.glosa"}}),_c('small',[_vm._v("(*) No acepta valores repetidos.")])],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.form.tipo_movimiento_id == '' || _vm.form.glosa == '' || _vm.form.guia_despacho_id == ''},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v(" Continuar ")]),_c('v-btn',{attrs:{"color":"error","to":{ name: 'Movimientos', params: { id: _vm.$route.params.id } }}},[_vm._v("Volver")])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"margin-bottom":"15px"},attrs:{"sm6":""}},[_c('v-text-field',{attrs:{"box":"","append-icon":"search","label":"Buscar producto","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                { text: 'SKU-ITEM', value: 'SKU' },
                { text: 'Producto', value: 'producto.nombre' },
                { text: 'Item', value: 'nombre' },
                { text: 'Categoría', value: 'categoria.nombre' },
                { text: 'Stock', value: 'cantidad_stock' },
                { text: 'Cantidad' }
              ],"items":_vm.stockProductos,"search":_vm.search,"rows-per-page-items":[10, 15, 25, 50, 100, 150]},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.SKU)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.producto.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.categoria.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.cantidad_stock)+" ")]),_c('td',{staticClass:"px-3"},[_c('v-text-field',{staticStyle:{"height":"60px !important","padding":"5px"},attrs:{"type":"number","solo":""},model:{value:(props.item.cantidad),callback:function ($$v) {_vm.$set(props.item, "cantidad", $$v)},expression:"props.item.cantidad"}})],1)])}}])})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.siguiente()}}},[_vm._v(" Continuar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.e1 = 1}}},[_vm._v("Volver")])],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md3":"","sm12":""}},[_c('v-card',{staticClass:"mb-5 md8",staticStyle:{"border":"2px solid #1976d2 !important","padding":"8px 12px 12px","border-radius":"12px"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"first"},[_c('h3',[_vm._v("Bodega:")]),_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"first"},[_c('h3',[_vm._v("N° de Guía:")]),_vm._v(_vm._s(_vm.form.guia_despacho_id))])]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"first"},[_c('h3',[_vm._v("Glosa:")]),_vm._v(_vm._s(_vm.form.glosa))])])])],1),_c('v-flex',{attrs:{"md9":"","sm12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                    { text: 'Producto' },
                    { text: 'Nombre' },
                    { text: 'Cantidad' },
                    { text: 'Acciones' }
                  ],"items":this.form.item_productos,"rows-per-page-items":[50, 100, 150]},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.producto)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.cantidad)+" ")]),_c('td',{staticClass:"px-3"},[_c('v-btn',{staticClass:"ma-0",attrs:{"small":"","icon":"","flat":"","color":"error"},on:{"click":function($event){return _vm.removeTodo(props.item.item_producto_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)],1)])}}])})],1)],1),(this.form.item_productos.length > 0)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingGuardar},on:{"click":_vm.submitCreateMovimiento}},[_vm._v(" Guardar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v("Volver")])],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"secundary-title":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-card-text',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[{ text: 'error' }],"items":_vm.errorItems,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item)+" ")])])}}])})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }